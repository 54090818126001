<template>
    <div class="login-section clearfix">
        <div class="right">
            <div class="login-form">
                <div class="inner" v-loading="bindloading">
                    <h1>Login Account</h1>
                    <div v-if="platformType==='wx' && !isBindWx" class="text-center mb-24 mt--30"
                         style="width: 300px; margin: auto;">
                        <el-alert :closable="false"
                                  type="error"
                                  title="Unbound enterprise wechat account"
                                  description="Please enter the code password to bind the enterprise wechat account">
                        </el-alert>
                    </div>
                    <div v-if="platformType==='zoho' && !isBindZoho" class="text-center mb-24 mt--30"
                         style="width: 300px; margin: auto;">
                        <el-alert :closable="false"
                                  type="error"
                                  title="Unbound Zoho account"
                                  description="Please enter the code password to bind the Zoho account">
                        </el-alert>
                    </div>
                    <el-form :model="form" :rules="rules" ref="form" label-position="left"
                             label-width="0px" size="small" class="login-ruleForm">
                        <template v-if="platformType!='IdentityPlatform'">
                            <el-form-item prop="username">
                                <el-input type="text" v-model="form.username" auto-complete="off"
                                          placeholder="Code"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input type="password" v-model="form.password" auto-complete="off"
                                          placeholder="Password"
                                          @keyup.enter.native="handleSubmit"></el-input>
                            </el-form-item>
                            <el-form-item class="mt-50">
                                <el-button type="primary" @click="handleSubmit" :loading="logining">
                                    SUBMIT
                                </el-button>
                            </el-form-item>
                            <el-row>
                                <el-col :span="24" class="text-right">
                                    <el-button type="text" size="small" @click="handleReset">Reset</el-button>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" class="text-center" style="margin-bottom:20px;">
                                    <el-button type="success" size="small" @click="idpLogin" :loading="idplogining">
                                        Portal+ Login
                                    </el-button>
                                </el-col>
                            </el-row>
                        </template>
                        <template v-else>
                            <el-row>
                                <el-col :span="24" class="text-center" style="margin-bottom:20px;">
                                    <div v-loading="idplogining"></div>
                                </el-col>
                            </el-row>
                        </template>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="left"><img src="../assets/img/login-image.jpg" alt="" width="500" class="img-responsive"></div>
    </div>
</template>

<script>
    import VueCookies from "vue-cookies";

    export default {
        name: "Login",
        data() {
            return {
                platformType: '',
                isBindZoho: true,
                isBindWx: true,
                bindloading: false,

                logining: false,
                form: {
                    username: '',
                    password: '',
                    userid: '',
                    customcode: '',
                    code: '',
                    type: '',
                },
                rules: {
                    username: [
                        {required: true, message: 'Please enter the code', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: 'Please enter the password', trigger: 'blur'},
                    ]
                },
                idplogining: false,
                appCenterLoginCode:'',
            };
        },
        methods: {
            handleSubmit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.logining = true;
                        var loginParams = {
                            "command": "/talents_login/login",
                            "data": {
                                "username": this.form.username,
                                "password": this.form.password,
                                "userid": this.form.userid,
                                "customcode": this.form.customcode, //wx
                                "code": this.form.code,  //zoho
                                "type": this.form.type,  //zoho
                            }
                        };
                        this.$WebApi(loginParams).then(res => {
                            this.logining = false;
                            if (res.code != 200) {
                                this.$message({message: 'login fail！', type: 'error'});
                                return;
                            } else {
                                //登录成功
                                if (res.user) {
                                    if (this.platformType==='wx') {
                                        this.isBindWx = true;
                                        this.$message({message: 'binding successful！', type: 'success'});
                                    }
                                    else if (this.platformType==='zoho') {
                                        this.isBindZoho = true;
                                        this.$message({message: 'binding successful！', type: 'success'});
                                    }
                                    // setCookie('COOKIE_NAME', 'PrivacyPolicyCookie');
                                    // $.cookie(name, value, {expires: 30, path: '/'});
                                    sessionStorage.setItem('bologin', true);
                                    sessionStorage.setItem('user', JSON.stringify(res.user));
                                    sessionStorage.setItem('token', res.token);
                                    // this.$cookies.set('tokenTime',res.token,'1d');
                                    // this.$setLoginTime();
                                    var t = Date.now()
                                    this.$cookies.set('LoginTime',t);
                                    this.$router.push({path: '/Home'});
                                } else {
                                    this.$message({message: 'login error！', type: 'error'});
                                }
                            }
                        });
                    } else {
                        console.log('login error');
                        return false;
                    }
                });
            },
            handleReset: function (e) {
                this.$refs['form'].resetFields()
            },
            userLogin(customCode, type, code) {
                /**
                 * 1. wx====
                 * 200 如果企业微信绑定了系统账号的，返回登录信息 数据结构和现在的登录成功返回的信息是一样的
                 * 400 不是企业微信传过来的会获取数据失败  就是返回的400
                 * 402 未绑定，需要引导他绑定系统账号
                 * customcode 企业微信接入
                 *
                 * 2. zoho====
                 * type 1: zoho接入
                 * 402 未绑定，需要引导他绑定系统账号
                 *
                 **/
                var params = {
                    "command": "/user/login",
                    "data": {
                        "customcode": customCode,
                        "type": type,
                        "code": code,
                    }
                };
                this.bindloading = true;
                this.$WebApi(params).then(res => {
                    this.bindloading = false;
                    if (res.code == 200) {
                        if (res.user) {
                            if (this.platformType === 'wx') {
                                this.isBindWx = true;
                            } else if (this.platformType === 'zoho') {
                                this.isBindZoho = true;
                            }

                            sessionStorage.setItem('bologin', true);
                            sessionStorage.setItem('user', JSON.stringify(res.user));
                            sessionStorage.setItem('token', res.token);
                            // this.$cookies.set('tokenTime',res.token,'1min');
                            // this.$setLoginTime();
                            var t = Date.now()
                            this.$cookies.set('LoginTime',t);
                            this.$router.push({path: '/Home'});
                        }
                    } else if (res.code == 402) {
                        this.form.userid = res.userid;
                        if (this.platformType === 'wx') {
                            this.isBindWx = false;
                        } else if (this.platformType === 'zoho') {
                            this.isBindZoho = false;
                        }
                    } else if (res.code == 400) {

                    }
                });
            },
            /**
             * identify platform===
             * code (only code)
             **/
            idpLogin(){
                var loginParams = {
                    "command": "/user/idpLogin",
                    "data": {}
                };
                this.idplogining = true;
                this.$WebApi(loginParams).then(res => {
                    this.idplogining = false;
                    if (res.code != 200) {
                        return
                    }
                    window.location.href = res.url
                });
            },
            idpLogin2(){
                var loginParams = {
                    "command": "/user/idpLogin",
                    "data": {code: this.appCenterLoginCode}
                };
                this.idplogining = true;
                this.$WebApi(loginParams).then(res => {
                    this.idplogining = false;
                    if (res.code == 200) {
                        if (res.user) {
                            sessionStorage.setItem('bologin', true);
                            sessionStorage.setItem('user', JSON.stringify(res.user));
                            sessionStorage.setItem('token', res.token);
                            var t = Date.now();
                            this.$cookies.set('LoginTime',t);
                            this.$router.push({path: '/Home'});
                        }
                    }
                });
            },

        },
        mounted() {
            this.form.customcode = this.$route.query.customcode;
            this.form.type = this.$route.query.type;
            var code = this.$route.query.code;

            //wx
            if (this.form.customcode) {
                this.platformType = 'wx';
                //获取wx code;
                var url = location.search; //获取url中"?"符后的字串
                var theRequest = new Object();
                if (url.indexOf("?") != -1) {
                    var str = url.substr(1);
                    var strs = str.split("&");
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
                    }
                }
                this.form.code = theRequest.code;
                if (this.form.customcode && this.form.code) {
                    this.userLogin(this.form.customcode, this.form.type, this.form.code);
                }
            }
            //zoho
            else if (this.form.type == 1) {
                this.platformType = 'zoho';
                this.form.code = this.$route.query.code;
                if (this.form.type && this.form.code) {
                    this.userLogin(this.form.customcode, this.form.type, this.form.code);
                }
            }
            //idp
            else if(!this.form.customcode && code){
                this.platformType = 'IdentityPlatform';
                this.appCenterLoginCode = code;
                this.idpLogin2();
            }
            // if ((this.form.customcode && this.form.code) || (this.form.type && this.form.code)) {
            //     this.userLogin(this.form.customcode, this.form.type, this.form.code);
            // }
        }
    }
</script>

<style scoped>

    .login-section {
        width: 1000px;
        margin: auto;
        margin-top: 80px;
        padding:0;
        background-color: #fff;
        box-shadow: 0 0 24px rgba(0, 0, 0, .1);
        clear: both;
        overflow: hidden;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }

    .login-section .left, .login-section .right {
        width: 50%;
        flex: 0 0 50%
    }

    .login-section .left img{
        width: 100%;
    }

    .login-section .right .login-form {
        height: 100%;
        width: 100%;
        color: #097ff5;
        display: flex;
        align-items: center
    }

    .login-section .right .login-form .inner {
        width: 100%
    }

    .login-section .right .login-form .inner h1 {
        text-align: center;
        margin-top: 70px;
        margin-bottom: 60px
    }

    .login-section .right .login-form .inner .login-ruleForm {
        width: 60%;
        margin: 0 auto
    }

    @media screen and (max-width: 1024px) {
        .login-section {
            width: 90%;
            display:block;
        }

        .login-section .left img{
            width: 100%;
        }
        .login-section .left, .login-section .right {
            width:100%;
        }
        .login-section .right .login-form .inner h1 {
            margin-top: 80px;
            margin-bottom: 40px
        }
    }

    @media screen and (max-width: 900px) {
        .login-section {
            width: 90%
        }

        .login-section .right .login-form .inner h1 {
            margin-top: 40px;
            margin-bottom: 30px
        }
    }

    .login-ruleForm >>> .el-input__inner {
        border-radius: 0;
        border: none;
        background-color: #f0f0f0;
        height: 44px;
        line-height: 44px;
        border-left: 4px solid #097ff5
    }

    .login-ruleForm .el-button {
        height: 44px
    }

    .login-ruleForm .el-button--primary {
        background-color: #097ff5;
        width: 100%
    }

    .login-ruleForm .el-button--primary:hover {
        border-color: #409eff;
        background-color: #409eff
    }
</style>